import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import { useAuth0 } from '@auth0/auth0-react';
import { Player } from '@lottiefiles/react-lottie-player';
import Joyride, { STATUS } from 'react-joyride';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Drawer, Pagination, Tag, Tooltip } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import {
  FilePdfOutlined,
  LinkOutlined,
  LineChartOutlined,
  DotChartOutlined,
  PictureOutlined,
  LikeOutlined,
  DislikeOutlined,
  LikeFilled,
  DislikeFilled,
  LikeTwoTone,
  DislikeTwoTone,
  FileOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  RedoOutlined,
  LoadingOutlined,
  SearchOutlined,
  BulbOutlined,
} from '@ant-design/icons';
import Chartrender from './Chartrender';
import filterhorizontal from '../../../../../../src/assets/images/filter-horizontal.svg';
import { Button, Dropdown, ConfigProvider } from 'antd';
import FollowUp from './FollowUp';
import axios from 'axios';
import LanguageDropdown from './LanguageDropDown';
import FileShareModal from './customModal/CustomModal';
import { Snackbar } from '@mui/material';
import CustomLoader from './CustomLoader';

const SingleChat = ({
  currentApp,
  contentSearchQuestion,
  onRelevantQuestionChange,
  onpdfOpen,
  onchatResponseComplete,
  onchartDataRecieve,
  onImageDataChange,
  oncontrollerRef,
  index,
  tagActiveIndex,
  ontagChange,
  questionsLength,
  setLatestChatImg,
  setLatestGraph,
  prevObj,
  setChatContext,
  isLastAnswer,
  lastQuestion,
}) => {
  const {
    useForReleventDocuments,
    useForReleventQuestionsKX,
    useGiveFeedback,
  } = useDatacallswithtoken();
  const currentgroup = useSelector((state) => state.PageData.currentApp.folder);
  const [run, setrun] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const userGroup = currentApp.folder;
  const [Loading, setLoading] = useState(true);
  let controllerRef = useRef(new AbortController());
  const [chatResponseComplete, setChatResponseComplete] = useState(false);
  const [isSocketError, setIsSocketError] = useState(false);
  const [data, setData] = useState();
  const [columns, setColumns] = useState();
  const [imageArr, setImageArr] = useState();
  const [files, setFiles] = useState();
  const [graphDataAll, setGraphDataAll] = useState();
  const [fileSummary, setFIleSummary] = useState();
  const [questionId, setQuestionId] = useState();
  const [searchType, setSearchType] = useState();
  const [followUpFiles, setFollowUpFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [saveResponseArr, setSaveResponseArr] = useState([]);
  const [saveTemporaryChatRes, setsaveTemporaryChatRes] = useState([]);
  const [responseHovered, setResponseHovered] = useState(false);
  const [showModifySorces, setShowModifySorces] = useState(true);
  const [shareModal, setShareModal] = useState(false);
  const [alertObj, setAlerObj] = useState({});
  const [filesToShare, setfilesToShare] = useState([]);
  const [answerToShare, setanswerToShare] = useState('');

  const [loaderMessage, setLoaderMessage] = useState(
    'Searching for the most relevant data'
  );
  const [currentApiCall, setCurrentApiCall] = useState('');

  useEffect(() => {
    const hasSeenQuestionGuide = localStorage.getItem('hasSeenQuestionGuide');
    if (!hasSeenQuestionGuide && chatResponseComplete) {
      setrun(true);
      localStorage.setItem('hasSeenQuestionGuide', 'true');
    }
  }, [chatResponseComplete]);

  useEffect(() => {
    let intervalId;
    if (Loading && !controllerRef.current.signal.aborted) {
      intervalId = setInterval(() => {
        setLoaderMessage((prevMessage) => {
          switch (currentApiCall) {
            case 'useForReleventDocuments':
              return prevMessage === 'Searching for the most relevant data'
                ? 'Composing the best possible answer'
                : 'Searching for the most relevant data';
            case 'useForReleventQuestionsKX':
            case 'HandleContentSearch':
              return 'Fact checking to ensure my answers are correct';
            default:
              return 'Searching for the most relevant data';
          }
        });
      }, 1500); // Change message every 1.5 seconds
    }
    return () => clearInterval(intervalId);
  }, [Loading, currentApiCall]);

  const getLoaderIcon = (message) => {
    switch (message) {
      case 'Searching for the most relevant data':
        return <SearchOutlined />;
      case 'Composing the best possible answer':
        return <LoadingOutlined />;
      case 'Fact checking to ensure my answers are correct':
        return <BulbOutlined />;
      default:
        return <SearchOutlined />;
    }
  };

  const steps = [
    {
      target: '#regenerateButton',
      content:
        'Click here to regenerate the answer if you encounter any errors.',
      disableBeacon: true,
    },
    // Add more steps if necessary
  ];

  const joyrideStyles = {
    options: {
      arrowColor: '#000',
      backgroundColor: '#ffffff',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#007bff',
      textColor: '#333333',
      zIndex: 1000,
      marginTop: '1em',
      height: '5rem',
    },
    tooltip: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#000',
      color: '#fff',
    },
    tooltipContent: {
      padding: '0',
      fontSize: '1.2rem',
      lineHeight: '1.5',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipFooter: {
      display: 'none', // This hides the footer where buttons are usually placed
    },
    buttonClose: {
      top: '-.75em',
      fontSize: '0.5rem', // Add this line to adjust the close button position
      color: 'white',
    },
  };
  // content search params (question,files,questionId,searchType,showfiles,mode,imageArr,last chat response)
  const items2 = [
    {
      label: (
        <a
          onClick={() =>
            HandleContentSearch(
              contentSearchQuestion,
              saveResponseArr[currentPage]?.files,
              questionId,
              searchType,
              saveResponseArr[currentPage]?.files.length,
              'Shorten',
              imageArr
            )
          }
        >
          <i className='mdi mdi-text-short' style={{ paddingRight: '10px' }} />
          Shorter
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a
          onClick={() =>
            HandleContentSearch(
              contentSearchQuestion,
              files,
              questionId,
              searchType,
              saveResponseArr[currentPage]?.files.length,
              'Expand',
              imageArr
            )
          }
        >
          <i
            className='mdi mdi-text-subject'
            style={{ paddingRight: '10px' }}
          />
          Longer
        </a>
      ),
      key: '1',
    },
    {
      label: (
        <a
          onClick={() =>
            HandleContentSearch(
              contentSearchQuestion,
              files,
              questionId,
              searchType,
              saveResponseArr[currentPage]?.files.length,
              'Simplify',
              imageArr
            )
          }
        >
          {' '}
          <i
            className='mdi mdi-check-underline'
            style={{ paddingRight: '10px' }}
          ></i>
          Simpler
        </a>
      ),
      key: '2',
    },
  ];
  useEffect(() => {
    setLatestGraph();
    // setLatestGraph()
    if (
      data &&
      data.length > 2 &&
      columns &&
      columns.length > 2 &&
      graphDataAll
    ) {
      onchartDataRecieve(data, columns, graphDataAll, 'graph', questionId);
      setLatestGraph({
        data: data,
        columns: columns,
        graphDataAll: graphDataAll,
        questionId: questionId,
        question: contentSearchQuestion,
      });
    }
  }, [data, columns, graphDataAll]);

  useEffect(() => {
    // setShowFollowUp(false)
    onpdfOpen();
    onImageDataChange();
    // if (updatedSourcesArr && updatedSourcesArr?.length>0) {
    //     // console.log(updatedSourcesArr,questionId)
    //     console.log(updatedSourcesArr.map(file => file.pdf_location))
    //     setSources(updatedSourcesArr.map(file => file.pdf_location));
    //     setFiles(updatedSourcesArr);
    //     setChatResponseComplete(false);
    //     onchatResponseComplete(false);
    //     // onSourcesArrChange(updatedSourcesArr.map(file => file.pdf_location), updatedSourcesArr, index)
    //     // oncontrollerRef(controllerRef)
    //     HandleContentSearch(contentSearchQuestion, updatedSourcesArr, questionId);
    // }else{
    HandleQuestion(contentSearchQuestion);
    // }
  }, []);

  const HandleQuestion = (contentSearchQuestion) => {
    setChatResponseComplete(false);
    onchatResponseComplete(false);
    // oncontrollerRef(controllerRef)
    setCurrentApiCall('useForReleventDocuments');
    setLoading(true);
    useForReleventDocuments(
      { question: contentSearchQuestion, folderName: userGroup },
      'newInsights'
    ).then((res) => {
      // setSources(res?.single_filepath);
      // console.log("res", res)
      // let imgPresent=true;
      // console.log(res?.images[0])
      setCurrentApiCall('useForReleventDocuments');

      let imgArr;
      if (res?.images[0]) {
        imgArr = res.images[0];
      }
      // let imageArr = [];

      // if (imgArr) {
      //   res?.files.slice(0, res?.show_files).forEach((file) => {
      //     const key = getLastPartOfPath(file.pdf_location);
      //     if (imgArr[key]) imageArr = [...imageArr, ...imgArr[key]];
      //   });
      // }
      // handleSaveResponse(
      //   '',
      //   res?.files.slice(0, res?.show_files),
      //   Math.min(res?.files.length, res?.show_files),
      //   imageArr
      // );
      // if (imageArr?.length > 0) {
      //   onImageDataChange(imageArr, 'img-tag', res?.ques_id);
      // }
      setImageArr(imgArr);
      console.log('imgArr', imgArr);
      setFiles(res.files);
      setQuestionId(res?.ques_id);
      // setFileUrl(res?.file_url)
      setFIleSummary(res?.file_summary[0]);

      // setFollowUpFiles(res?.files.slice(res?.single_filepath.length, res?.files.length))
      setFollowUpFiles(res?.files);
      // console.log(res?.files.slice(res?.single_filepath.length, res?.files.length));
      res?.search_type
        ? setSearchType(res?.search_type)
        : setSearchType('Multi-search');
      setShowModifySorces(res?.modifySource);
      // settriggerWebSearch(res?.triggerWebSearch);
      // res?.show_files ? setShowFIles(res?.show_files) : setShowFIles(res?.single_filepath.length)
      HandleContentSearch(
        contentSearchQuestion,
        res.files,
        res?.ques_id,
        res?.search_type || 'Multi-search',
        res?.show_files || res?.single_filepath.length,
        undefined,
        imgArr,
        res?.triggerWebSearch
      );
      // content search params (question,files,questionId,searchType,showfiles,mode,imageArr,last chat response)
    });
  };

  // function HandleFeedback(feedBack) {
  //     console.log("feed", feedBack)
  //     setFeedBack(feedBack)
  //     useGiveFeedback({ questionId: String(questionId), feedback: feedBack }).then(res => {
  //         console.log(res)
  //     }).catch(err => {
  //         console.log(err.message)
  //     })
  // }
  // console.log("imageArr",imageArr)

  function getLastPartOfPath(path) {
    const parts = path.split('/');
    console.log('parts', parts[parts.length - 1]);
    return parts[parts.length - 1];
  }

  function handleClose() {
    setAlerObj((prev) => ({ ...prev, show: false }));
  }
  const action = (
    <React.Fragment>
      <CloseIcon fontSize='small' onClick={handleClose} />
    </React.Fragment>
  );

  const HandleContentSearch = async (
    question,
    files,
    questionId,
    searchType,
    showFiles,
    mode,
    images,
    triggerWebSearch
  ) => {
    setCurrentApiCall('HandleContentSearch');
    setChatResponseComplete(false);
    onchatResponseComplete(false);
    if (!data && !columns) {
      onImageDataChange();
    }
    if (
      data &&
      data.length > 2 &&
      columns &&
      columns.length > 2 &&
      graphDataAll
    )
      setLatestGraph({
        data: data,
        columns: columns,
        graphDataAll: graphDataAll,
        questionId: questionId,
        question: contentSearchQuestion,
      });
    setLoading(true);
    controllerRef.current = new AbortController();
    oncontrollerRef(controllerRef);
    console.log(controllerRef);
    let pdfLocations;
    if (triggerWebSearch === true) {
      pdfLocations = files.map((obj) => obj.url);
    } else {
      pdfLocations = files.map((obj) => obj.pdf_location);
    }
    setCurrentPage(saveResponseArr.length);
    setChatContext((prev) => ({ ...prev, [question]: '' }));
    let imageArr = [];

    if (images) {
      files.slice(0, showFiles).forEach((file) => {
        const key = getLastPartOfPath(file.pdf_location);
        if (images[key]) imageArr = [...imageArr, ...images[key]];
      });
    }
    onImageDataChange(imageArr, 'img-tag', questionId);
    setLatestChatImg({
      img: imageArr,
      questionId: questionId,
      question: contentSearchQuestion,
    });
    const data_config = {
      question: question,
      ques_id: questionId,
      folderName: currentgroup,
      fileName: pdfLocations,
      type: searchType,
      show_files: showFiles,
      trigger: triggerWebSearch,
      ...(mode
        ? {
            mode: mode,
            previous_answer: saveResponseArr[currentPage].chatResponse,
            previous_question: prevObj ? prevObj[1] : question,
          }
        : {
            previous_answer: prevObj ? prevObj[0] : '',
            previous_question: prevObj ? prevObj[1] : '',
          }),
    };
    handleSaveResponse(
      '',
      files.slice(0, showFiles),
      Math.min(files.length, showFiles),
      imageArr
    );
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data_config),
      signal: controllerRef.current.signal,
    };
    console.log('Error Here ');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let fullResponse = '';
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          fullResponse += chunk;
          // setChatresponse(fullResponse);
          setSaveResponseArr((prev) => [
            ...prev.slice(0, -1),
            { ...prev[prev.length - 1], chatResponse: fullResponse },
          ]);
          setsaveTemporaryChatRes((prev) => [
            ...prev.slice(0, -1),
            { ...prev[prev.length - 1], chatResponse: fullResponse },
          ]);
          setLoading(false);
        }
        if (controllerRef.current.signal.aborted) {
          console.log('aborted manually ', controllerRef.current.signal);
          // HandleReleventQuestions(question, fullResponse);
          // if (index < 1) {
          //   setrun(true);
          // }
          console.log(index);
          setChatResponseComplete(true);
          onchatResponseComplete(true);
          console.log('Fetch aborted');
          break;
        }
        if (done) {
          // if (index < 1) {
          //   setrun(true);
          // }
          console.log(index);
          setChatResponseComplete(true);
          onchatResponseComplete(true);
          // if (followUpFiles.length > 0 && !mode) setShowFollowUp(true)
          setChatContext((prev) => ({ ...prev, [question]: fullResponse }));
          // console.log(imageArr)
          // if (imageArr.length > 0) {
          //   onImageDataChange(imageArr, 'img-tag', questionId);
          // }
          // if (!data  && !columns  ){

          // }

          HandleReleventQuestions(question, fullResponse);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.name === 'AbortError') {
        setChatResponseComplete(true);
        // if (index < 1) {
        //   setrun(true);
        // }
        console.log(index);
        onchatResponseComplete(true);
        // console.log("Fetch aborted");
      } else {
        console.error('Fetch error:', error);
        setLoading(false);
        setChatResponseComplete(true);
        // if (index < 1) {
        //   setrun(true);
        // }
        console.log(index);
        onchatResponseComplete(true);
        // setChatresponse(error.message);
      }
    }
  };

  function handleSaveResponse(chatResponse, files, showFiles, images) {
    const filteredImages = images.filter((image) => image !== null);
    const temp = {
      chatResponse: chatResponse,
      files: files,
      showFiles: showFiles,
      images: filteredImages,
    };
    setSaveResponseArr((prev) => [...prev, temp]);
    setsaveTemporaryChatRes((prev) => [...prev, temp]);
  }

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        let questions = [];
        Object.keys(res).forEach((key) => {
          const obj = {
            fileName: fileName,
            question: res[key],
          };
          questions.push(obj);
        });
        onRelevantQuestionChange(Object.values(res));
      }
    );
  };

  const handleTagClose = (removedTag, index) => {
    console.log(removedTag);
    setChatResponseComplete(false);
    onchatResponseComplete(false);
    if (removedTag.pdf_location == tagActiveIndex?.source) ontagChange();
    const chatResponse = saveResponseArr[currentPage]?.chatResponse;
    const showFiles = saveResponseArr[currentPage]?.showFiles;
    const files = saveResponseArr[currentPage]?.files.slice(0, showFiles - 1);
    HandleContentSearch(
      contentSearchQuestion,
      files.filter((file) => file !== removedTag),
      questionId,
      searchType,
      showFiles - 1,
      undefined,
      imageArr
    );
    // setSources(prevSources => prevSources.filter(source => source !== removedTag.pdf_location));
    // setShowFollowUp(false);
  };

  const handleFollowUpResponse = (response) => {
    // if (response == "yes") { setShowFollowUp(false); return }
    // if (response == "no")  {return}
    const chatResponse = saveResponseArr[currentPage]?.chatResponse;
    const showFiles = saveResponseArr[currentPage]?.showFiles;
    const files = saveResponseArr[currentPage]?.files.slice(0, showFiles - 1);

    HandleContentSearch(
      contentSearchQuestion,
      response,
      questionId,
      searchType,
      response.length,
      undefined,
      imageArr
    );
    setFiles(response);
  };
  const handlePageChange = (val) => {
    onpdfOpen();
    const tempCurpage = val == 'prev' ? currentPage - 1 : currentPage + 1;
    if (
      data &&
      data.length > 2 &&
      columns &&
      columns.length > 2 &&
      graphDataAll
    ) {
      onchartDataRecieve(data, columns, graphDataAll, 'graph', questionId);
    } else {
      onImageDataChange(
        saveResponseArr[tempCurpage].images,
        'img-tag',
        questionId
      );
    }
    setCurrentPage(tempCurpage);
  };

  const handleRegerate = () => {
    HandleContentSearch(
      contentSearchQuestion,
      saveResponseArr[currentPage]?.files,
      questionId,
      searchType,
      saveResponseArr[currentPage]?.files.length,
      undefined,
      imageArr
    );
  };

  const handleTranslateAnswer = async (language) => {
    try {
      const response = await axios.post(
        'https://translation.googleapis.com/language/translate/v2',
        {},
        {
          params: {
            q: saveTemporaryChatRes[currentPage].chatResponse,
            target: language,
            key: 'AIzaSyBU5F265RjIc3wHXisnPNkSmcSfoaW4cWo',
          },
        }
      );
      //   console.log('translate response', response.data);
      const tempSaveResArray = [...saveResponseArr];
      tempSaveResArray[currentPage].chatResponse =
        response.data.translations[0].translatedText;

      setSaveResponseArr(tempSaveResArray);
      // setsaveTemporaryChatRes(tempSaveResArray);
    } catch (error) {
      console.error('Error translating response:', error);
    }
    //   }
  };

  const CustomTable = ({ children }) => (
    <table
      style={{
        border: '1px solid black',
        width: '100%',
        borderCollapse: 'collapse',
      }}
    >
      {children}
    </table>
  );

  const CustomTableCell = ({ children }) => (
    <td style={{ border: '1px solid black', padding: '8px' }}>{children}</td>
  );

  return (
    <React.Fragment>
      <Joyride
        steps={steps}
        run={run}
        styles={joyrideStyles}
        continuous={false}
        showSkipButton={false}
        showProgress={false}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        floaterProps={{
          disableAnimation: true,
          styles: {
            arrow: {
              length: 8,
              spread: 12,
            },
          },
        }}
        // callback={(data) => {
        //   if (index >= 1) {
        //     setrun(false);
        //   }
        // }}
      />
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              width: '100%',
              color: '#666',
              fontSize: '.8rem,',
              padding: Loading ? '0px' : '5px',
              borderBottom:
                chatResponseComplete && index != questionsLength - 1
                  ? '2px solid #E6E6E6'
                  : '',
              paddingBottom: index != questionsLength - 1 ? '20px' : '',
            }}
            onMouseEnter={() => {
              setResponseHovered(true);
            }}
            onMouseLeave={() => {
              setResponseHovered(false);
            }}
          >
            <FileShareModal
              setShareModal={setShareModal}
              shareModal={shareModal}
              file={filesToShare}
              fileSummary={answerToShare}
              setAlerObj={setAlerObj}
              lastQuestion={lastQuestion}
            />
            {Loading && !controllerRef.current.signal.aborted ? (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {/* <Player
                src='https://lottie.host/5d785403-97c8-440d-b61e-eb5b75040e50/DUgoSoG3Jl.json'
                background='transparent'
                speed='1'
                style={{ width: '50px', height: 'auto', margin: '0px' }}
                loop
                autoplay
              /> */}
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  className='my-3 mx-3'
                >
                  {getLoaderIcon(loaderMessage)}
                  {loaderMessage}...
                </span>
              </div>
            ) : (
              <div

              // style={{
              //   borderBottom:
              //     chatResponseComplete && index != questionsLength - 1
              //       ? '2px solid #E6E6E6'
              //       : '',
              //   paddingBottom: index != questionsLength - 1 ? '20px' : '',
              // }}
              >
                {saveResponseArr.length > 0 && (
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      table: CustomTable,
                      td: CustomTableCell,
                      th: CustomTableCell, // Customize th similarly if needed
                    }}
                  >
                    {saveResponseArr[currentPage].chatResponse}
                  </Markdown>
                )}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ width: '60%' }}>
                {saveResponseArr &&
                  saveResponseArr.length > 0 &&
                  saveResponseArr[currentPage].files
                    .slice(0, saveResponseArr[currentPage].showFiles)
                    .map((source, index) => (
                      <Tooltip
                        key={index}
                        placement='bottomLeft'
                        title={source.fileName
                          .replace(/%20/g, ' ')
                          .replace(/_/g, ' ')
                          .replace(/%/g, ' ')}
                      >
                        <Tag
                          onClick={() => {
                            if (source.pdf_location?.endsWith('.pdf')) {
                              onpdfOpen(source, questionId);
                            } else {
                              window.open(source.url, '_blank');
                            }
                          }}
                          icon={
                            source.pdf_location?.endsWith('.pdf') ? (
                              <FilePdfOutlined />
                            ) : (
                              <LinkOutlined />
                            )
                          }
                          // onClose={() => { handleTagClose(source, index) }}
                          style={{
                            marginBottom: '5px',
                            padding: '5px 10px',
                            fontSize: '.8rem',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            background:
                              tagActiveIndex?.questionId == questionId &&
                              tagActiveIndex?.source?.pdf_location ==
                                source.pdf_location
                                ? 'black'
                                : 'white',
                            color:
                              tagActiveIndex?.questionId == questionId &&
                              tagActiveIndex?.source?.pdf_location ==
                                source.pdf_location
                                ? 'white'
                                : 'black',
                          }}
                        >
                          {source.source}
                        </Tag>
                      </Tooltip>
                    ))}

                {/* {console.log("data", data, columns, graphDataAll)} */}

                {data &&
                  data.length > 2 &&
                  columns &&
                  columns.length > 2 &&
                  graphDataAll && (
                    <>
                      <Tag
                        onClick={() => {
                          onchartDataRecieve(
                            data,
                            columns,
                            graphDataAll,
                            'graph',
                            questionId
                          );
                        }}
                        icon={<DotChartOutlined />}
                        style={{
                          marginBottom: '5px',
                          padding: '5px 10px',
                          fontSize: '.8rem',
                          cursor: 'pointer',
                          background:
                            tagActiveIndex?.questionId == questionId &&
                            tagActiveIndex?.graph == 'graph'
                              ? 'black'
                              : 'white',
                          color:
                            tagActiveIndex?.questionId == questionId &&
                            tagActiveIndex?.graph == 'graph'
                              ? 'white'
                              : 'black',
                        }}
                      >
                        Visualization
                      </Tag>
                    </>
                  )}
                {saveResponseArr &&
                  saveResponseArr.length > 0 &&
                  saveResponseArr[currentPage].images.length > 0 && (
                    <Tag
                      key='img-tag'
                      onClick={() => {
                        onImageDataChange(
                          saveResponseArr[currentPage].images,
                          'img-tag',
                          questionId
                        );
                      }}
                      icon={<PictureOutlined />}
                      style={{
                        marginBottom: '5px',
                        padding: '5px 10px',
                        fontSize: '.8rem',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        background:
                          tagActiveIndex?.questionId == questionId &&
                          tagActiveIndex?.imgId == 'img-tag'
                            ? 'black'
                            : 'white',
                        color:
                          tagActiveIndex?.questionId == questionId &&
                          tagActiveIndex?.imgId == 'img-tag'
                            ? 'white'
                            : 'black',
                      }}
                    >
                      Images
                    </Tag>
                  )}
              </div>
              {chatResponseComplete && (
                // <div
                //   style={{
                //     display: 'flex',
                //     gap: '10px',
                //     color: '#b3b3b3',
                //     fontSize: '16px',
                //     alignItems: 'center',
                //     minWidth: '47px',
                //     minHeight: '30px',
                //   }}
                // >
                //   {(responseHovered || saveResponseArr.length - 1 === index) &&
                //     saveResponseArr &&
                //     saveResponseArr.length > 1 && (
                //       <div
                //         className='pagination'
                //         style={{
                //           fontSize: '12px',
                //           color: '#b3b3b3',
                //           marginRight: '5px',
                //         }}
                //       >
                //         <LeftOutlined
                //           style={{
                //             color: currentPage <= 0 ? '#b3b3b3' : 'black',
                //             pointerEvents: currentPage <= 0 ? 'none' : '',
                //             marginRight: '5px',
                //           }}
                //           onClick={() => handlePageChange('prev')}
                //         />
                //         {currentPage + 1}/{saveResponseArr.length}
                //         <RightOutlined
                //           onClick={() => handlePageChange('next')}
                //           style={{
                //             color:
                //               currentPage + 1 == saveResponseArr.length
                //                 ? '#b3b3b3'
                //                 : 'black',
                //             pointerEvents:
                //               currentPage + 1 == saveResponseArr.length
                //                 ? 'none'
                //                 : '',
                //             marginLeft: '5px',
                //           }}
                //         />
                //       </div>
                //     )}

                //   {/* {feedBack == null ? (<><LikeOutlined title="Like response" onClick={() => { HandleFeedback(true) }} /> <DislikeOutlined title="Dislike response" onClick={() => { HandleFeedback(false) }} /></>) :
                //                               (feedBack == true ? <><LikeTwoTone title="Remove like from response" twoToneColor="#b3b3b3, rgba(179, 179, 179, 0.5)" onClick={() => { HandleFeedback(null) }} /> <DislikeOutlined title="Dislike response" onClick={() => { HandleFeedback(false) }} /></> :
                //                                   <><LikeOutlined title="Like response" onClick={() => { HandleFeedback(true) }} /> <DislikeTwoTone title="Remove dislike from response" twoToneColor="#b3b3b3, rgba(179, 179, 179, 0.5)" onClick={() => { HandleFeedback(null) }} /></>)} */}
                //   {responseHovered && (
                //     <span title='Translate response'>
                //       {' '}
                //       <LanguageDropdown
                //         title='Translate Answer'
                //         handleTranslateAnswer={(val) => {
                //           handleTranslateAnswer(val);
                //         }}
                //       />{' '}
                //     </span>
                //   )}
                //   {responseHovered &&
                //     currentPage == saveResponseArr.length - 1 &&
                //     index == questionsLength - 1 && (
                //       <ConfigProvider
                //         theme={{ token: { colorPrimary: 'black' } }}
                //       >
                //         <RedoOutlined
                //           title='Regenerate response'
                //           onClick={() => handleRegerate()}
                //           style={{ cursor: 'pointer' }}
                //         />
                //         <Dropdown
                //           style={{ fontSize: '14px', cursor: 'pointer' }}
                //           menu={{ items: items2 }} // Ensure this structure is correct
                //           trigger={['click']}
                //         >
                //           <a
                //             title='Modify response'
                //             onClick={(e) => {
                //               e.preventDefault();
                //               console.log(e);
                //             }}
                //           >
                //             <Button type='text'>
                //               <img src={filterhorizontal} alt='chat-dropdown' />
                //             </Button>
                //           </a>
                //         </Dropdown>
                //       </ConfigProvider>
                //     )}
                // </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    color: '#b3b3b3',
                    fontSize: '16px',
                    alignItems: 'center',
                    minWidth: '47px',
                    minHeight: '30px',
                  }}
                >
                  {(responseHovered || isLastAnswer) && (
                    <>
                      {saveResponseArr && saveResponseArr.length > 1 && (
                        <div
                          className='pagination'
                          style={{
                            fontSize: '12px',
                            color: '#b3b3b3',
                            marginRight: '5px',
                          }}
                        >
                          <LeftOutlined
                            style={{
                              color: currentPage <= 0 ? '#b3b3b3' : 'black',
                              pointerEvents: currentPage <= 0 ? 'none' : '',
                              marginRight: '5px',
                            }}
                            onClick={() => handlePageChange('prev')}
                          />
                          {currentPage + 1}/{saveResponseArr.length}
                          <RightOutlined
                            onClick={() => handlePageChange('next')}
                            style={{
                              color:
                                currentPage + 1 == saveResponseArr.length
                                  ? '#b3b3b3'
                                  : 'black',
                              pointerEvents:
                                currentPage + 1 == saveResponseArr.length
                                  ? 'none'
                                  : '',
                              marginLeft: '5px',
                            }}
                          />
                        </div>
                      )}

                      <span title='Translate response'>
                        <LanguageDropdown
                          title='Translate Answer'
                          handleTranslateAnswer={(val) => {
                            handleTranslateAnswer(val);
                          }}
                        />
                      </span>

                      {currentPage == saveResponseArr.length - 1 &&
                        index == questionsLength - 1 && (
                          <ConfigProvider
                            theme={{ token: { colorPrimary: 'black' } }}
                          >
                            <RedoOutlined
                              id='regenerateButton'
                              title='Regenerate response'
                              onClick={() => handleRegerate()}
                              style={{ cursor: 'pointer', color: '#000000' }}
                            />
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setfilesToShare(
                                  saveResponseArr[saveResponseArr.length - 1]
                                    ?.files
                                );
                                setanswerToShare(
                                  `Q: ${lastQuestion}\n\n${
                                    saveResponseArr[saveResponseArr.length - 1]
                                      ?.chatResponse
                                  }`
                                );
                                setShareModal(true);
                              }}
                              className='border-0 bg-transparent'
                              title='Share'
                            >
                              <i
                                className='fa fa-share'
                                style={{
                                  fontSize: '13px',
                                  color: '#7f7f7f',
                                  cursor: 'pointer',
                                  color: '#000000',
                                }}
                              />
                            </button>
                            <Dropdown
                              style={{
                                fontSize: '14px',
                                cursor: 'pointer',
                                color: '#000000',
                              }}
                              menu={{ items: items2 }}
                              trigger={['click']}
                            >
                              <span
                                title='Modify response'
                                onClick={(e) => {
                                  e.preventDefault();
                                  console.log(e);
                                }}
                                style={{ color: '#000000' }}
                              >
                                <button
                                  style={{
                                    color: '#000000',
                                    border: 'none',
                                    background: 'transparent',
                                  }}
                                >
                                  <img
                                    src={filterhorizontal}
                                    alt='chat-dropdown'
                                    style={{
                                      color: '#000000',
                                      // backgroundColor: '#000000',
                                    }}
                                  />
                                  {/* <i class='fas fa-sliders-h'></i> */}
                                </button>
                              </span>
                            </Dropdown>
                          </ConfigProvider>
                        )}
                    </>
                  )}
                </div>
              )}
            </div>
            <Snackbar
              open={alertObj.show}
              autoHideDuration={2000}
              onClose={handleClose}
              message={alertObj.message}
              action={action}
            />

            {showModifySorces && (
              <>
                {chatResponseComplete &&
                  index == questionsLength - 1 &&
                  currentPage == saveResponseArr.length - 1 && (
                    <FollowUp
                      questionId={questionId}
                      question={contentSearchQuestion}
                      onfollowUpSelect={handleFollowUpResponse}
                      userGroup={currentApp.folder}
                      files={followUpFiles}
                      imgObj={imageArr}
                      selectedFiles={saveResponseArr[currentPage].files}
                      fileSummary={fileSummary}
                    />
                  )}
              </>
            )}
          </div>
          {!isSocketError && currentApp?.schema_id && (
            <Chartrender
              setIsSocketError={setIsSocketError}
              question={contentSearchQuestion}
              schemaid={currentApp?.schema_id}
              setData={setData}
              setColumns={setColumns}
              setGraphDataAll={setGraphDataAll}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleChat;
