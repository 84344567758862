import React, { useState, useEffect, useRef } from 'react';
import { changeChatQuestions } from '../../../../../store/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import './style/style.css';
import { Tooltip } from 'antd';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import ArrowUpward from '../../../../../assets/images/upward-arrow-chat.svg';

const SUBSCRIPTION_KEY = process.env.REACT_APP_SPEECH_SDK_KEY;
const REGION = 'centralindia';

const SearchBar = ({
  isLoading,
  groupName,
  showSearchBar,
  questionsToRender,
  onSearchbarHandlerChange,
  onPauseStreaming,
}) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isListening, setIsListening] = useState(false);

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const inputRef = useRef(null);
  const recognizerRef = useRef(null);
  const [isTranslationComplete, setIsTranslationComplete] = useState(false);
  const [translatedText, setTranslatedText] = useState('');

  const [search, setSearch] = useState('');

  const initializeRecognizer = () => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      SUBSCRIPTION_KEY,
      REGION
    );
    speechConfig.speechRecognitionLanguage = 'en-US';
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    recognizerRef.current = new SpeechSDK.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizerRef.current.recognizing = (s, e) => {
      setSearch(e.result.text);
    };

    recognizerRef.current.recognized = (s, e) => {
      if (e.result.reason == SpeechSDK.ResultReason.RecognizedSpeech) {
        // setSearch(e.result.text);
        setTimeout(() => {
          translateText(e.result.text);
        }, 500);
      } else if (e.result.reason == SpeechSDK.ResultReason.NoMatch) {
        setIsListening(false);
      }
    };

    recognizerRef.current.canceled = (s, e) => {
      if (e.reason == SpeechSDK.CancellationReason.Error) {
      }
      setIsListening(false);
    };

    recognizerRef.current.sessionStopped = (s, e) => {
      setIsListening(false);
    };
  };

  useEffect(() => {
    initializeRecognizer();
    return () => {
      if (recognizerRef.current) {
        recognizerRef.current.close();
      }
    };
  }, []);

  const handleSearch = (e, query) => {
    e?.preventDefault();
    const searchQuery = query || search;
    if (searchQuery.length > 0) {
      console.log(searchQuery);
      onSearchbarHandlerChange(searchQuery);
      setSearch('');
    }
  };
  const startListening = () => {
    if (recognizerRef.current) {
      setIsListening(true);

      recognizerRef.current.recognizeOnceAsync(
        (result) => {
          if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          } else {
            console.log(
              'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.'
            );
          }
          setIsListening(false);
        },
        (err) => {
          console.log(`ERROR: ${err}`);
          setIsListening(false);
        }
      );
    }
  };

  const stopListening = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      setIsListening(false);
    }
  };

  const translateText = async (text) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: text }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/translate/`,
        options
      );
      const data = await response.json();
      setTranslatedText(data.translated_text);
      setSearch(data.translated_text);
      setIsTranslationComplete(true);
    } catch (error) {
      console.error('Translation failed:', error);
      setTranslatedText('Translation failed. Please try again.');
      setIsTranslationComplete(true);
    } finally {
      stopListening();
    }
  };

  useEffect(() => {
    if (isTranslationComplete) {
      handleSearch(null, translatedText);
      setIsTranslationComplete(false);
    }
  }, [isTranslationComplete, translatedText]);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      {!isLoading && questionsToRender?.length > 0 ? (
        <div style={{ width: '100%' }} className='hidden-scrollbar'>
          <div
            style={{
              color: '#505D69',
              margin: '0em 0em .5em 0em',
              fontSize: '.75rem',
              padding: '0px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: '0px',
                display: 'inline',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Suggestions:
            </p>
            {questionsToRender?.slice(0, 2)?.map((each, i) => {
              return (
                <p
                  key={i}
                  onClick={(e) => {
                    if (showSearchBar) {
                      setSearch(each);
                      handleSearch(e, each);
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    margin: '0px',
                    display: 'inline',
                    paddingLeft: '15px',
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 'bolder',
                  }}
                >
                  {each}
                  <span>
                    <i className='mdi mdi-arrow-top-right'></i>
                  </span>
                </p>
              );
            })}
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '3px',
          border: '1px solid #ccc',
          borderRadius: '7px',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
        }}
        className='ncs-search'
      >
        <form
          onSubmit={(e) => {
            setIsListening(false);
            SpeechRecognition.stopListening();
            resetTranscript();
            setSearch('');
            handleSearch(e);
          }}
          autoComplete='off'
          style={{
            width: '100%',
            height: '41px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            opacity: isLoading ? '30%' : '100%',
          }}
        >
          <input
            style={{
              width: '100%',
              height: '21px',
              border: 'none',
              fontSize: '1rem',
              background: 'none',
            }}
            disabled={isLoading}
            type='text'
            placeholder={isLoading ? 'Loading...' : 'Ask follow up question'}
            name='search'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setIsListening(false);
              SpeechRecognition.stopListening();
              resetTranscript();
            }}
            ref={inputRef}
          />
          {search && (
            <i
              style={{
                padding: '10px',
                fontSize: '20px',
                color: '#aaa',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSearch('');
              }}
              disabled={!showSearchBar}
              className='bx bx-x'
            />
          )}
          {SpeechSDK ? (
            <i
              style={{ fontSize: '20px', color: '#aaa', cursor: 'pointer' }}
              disabled={!showSearchBar}
              onClick={(e) => {
                e.preventDefault();
                if (!isLoading) {
                  if (!isListening) {
                    startListening();
                  } else {
                    stopListening();
                  }
                }
              }}
              className={
                isListening ? 'bx bx-microphone' : 'bx bx-microphone-off'
              }
            />
          ) : null}
        </form>
        <div
          style={{
            padding: '0px 10px',
            cursor: 'pointer',
            height: '41px',
            width: 'fitcontent',
            display: 'flex',
            borderLeft: '1px solid #cccccc',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <>
              <Tooltip placement='top' title='Stop generating answer'>
                <span
                  id='stop-streaming'
                  style={{
                    width: '18px',
                    height: '18px',
                    background: 'black',
                    borderRadius: '3px',
                  }}
                  onClick={() => {
                    onPauseStreaming(true);
                  }}
                ></span>
              </Tooltip>
            </>
          ) : (
            <img
              src={ArrowUpward}
              style={{
                opacity: search.length == 0 ? '20%' : '100%',
                height: '28px',
                width: '28px',
              }}
              onClick={() => {
                setSearch('');
                handleSearch();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
